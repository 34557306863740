import React, { Component } from 'react';
import { connect } from 'react-redux';

// Get time in Indonesia
const LIST_HARI = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
const LIST_BULAN = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];

const TANGGAL = new Date().getDate();
const XHARI = new Date().getDay();
const XBULAN = new Date().getMonth();
const XTAHUN = new Date().getYear();

const HARI = LIST_HARI[XHARI];
const BULAN = LIST_BULAN[XBULAN];
const TAHUN = (XTAHUN < 1000) ? XTAHUN + 1900 : XTAHUN;

// list icon
const LOGO_PASANGKAYU = require('../assets/picture/logo-pasangkayu.png');

// Bagian Header
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  showMenu=() => {
    this.setState({
      showMenu: !this.state.showMenu,
    });
  }

  render() {
    const { nowTime } = this.props;
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light header sticky-top">
        <img height="40" alt="Logo Pasangkayu" src={LOGO_PASANGKAYU} />
        <div className="mr-auto">
          <h6 className="text-darkGray mr-5 ml-3">
            {' '}
            <b>Cuaca Pasangkayu</b>
            {' '}
          </h6>

          <div className="row ml-3">
            <div className="text-darkGray mr-3 mt-auto mb-auto">
              {' '}
              {HARI}
  ,
              {' '}
              {TANGGAL}
              {' '}
              {BULAN}
              {' '}
              {TAHUN}
            </div>
            <div className="text-darkGray">
              {' '}
              {(`0${new Date(nowTime).getHours()}`).slice(-2)}
  :
              {(`0${new Date(nowTime).getMinutes()}`).slice(-2)}
            </div>
          </div>
        </div>

      </nav>
    );
  }
}


const mapDispatchToProps = {

};

const mapStateToProps = (state) => ({
  nowTime: state.nowTime,
});


export default connect(
  mapStateToProps, mapDispatchToProps,
)(Header);
