import {
  POPUP_ADD_USER, ADD_PARAMETER_PROP, ADD_DEVICE_DATA,
  SET_INDEX_DEVICE, UPDATE_TIME, UPDATE_LIST_VALUE, SELECT_USER,
} from '../actions/actions';

const initialState = {
  parameterProp: '',
  popupAddUser: false,
  dataDevice: [],
  indexDevice: null,
  nowTime: 0,
  parameterName: {
    temp: 'Suhu',
    hum: 'Kelembaban',
    press: 'Tekanan Udara',
    rain: 'Curah Hujan',
    winds: 'Kecepatan Angin',
    windd: 'Arah Angin',
    solar: 'Radiasi Matahari',
  },
  listValue: {},
  selectedUser: [],

};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case POPUP_ADD_USER:
      return {
        ...state,
        popupAddUser: action.content,
      };

    case ADD_PARAMETER_PROP:
      return {
        ...state,
        parameterProp: action.content,
      };

    case ADD_DEVICE_DATA:
      return {
        ...state,
        dataDevice: action.content,
      };

    case SET_INDEX_DEVICE:
      return {
        ...state,
        indexDevice: action.content,
      };

    case UPDATE_TIME:
      return {
        ...state,
        nowTime: action.content,
      };

    case UPDATE_LIST_VALUE:
      return {
        ...state,
        listValue: action.content,
      };

    case SELECT_USER:
      return {
        ...state,
        selectedUser: action.content,
      };

    default:
      return state;
  }
}

export default rootReducer;
