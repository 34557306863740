import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GetChartData } from '../api';


// timestamp for oneday and onehour
const ONEDAY = 24 * 3600 * 1000;
const ONEHOUR = 1 * 3600 * 1000;

let timestampNow = Math.floor(Date.now());

//set icon
const ICON_PARAM = (icon) => require(`../assets/picture/icon/${icon}.svg`);

class TableUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataTemp:[],
      dataRain:[],
      dataWind:[]
    };
  }

  componentDidMount(){

    //get parameter data
    this.getDataParam();

    //update data every one hour
    setInterval(this.getDataParam, ONEHOUR);

  }


  getDataParam=()=>{
    //get data temp
    GetChartData({
      sensor: 'temp',
      'timestamp[$gte]': (timestampNow - ONEDAY) + (1 * ONEHOUR),
      'timestamp[$lte]': (timestampNow - ONEDAY) + (12 * ONEHOUR),
    }).then((response) => {
      this.setState({
        dataTemp: response.data,
      });
    });

    // get data rain
    GetChartData({
      sensor: 'rain',
      'timestamp[$gte]': timestampNow - ONEDAY,
      'timestamp[$lte]': (timestampNow - ONEDAY) + (11 * ONEHOUR),
    }).then((response) => {
      this.setState({
        dataRain: response.data,
      });
    });

    //get data wind
    GetChartData({
      sensor: 'winds',
      'timestamp[$gte]': timestampNow - ONEDAY,
      'timestamp[$lte]': (timestampNow - ONEDAY) + (11 * ONEHOUR),
    }).then((response) => {
      this.setState({
        dataWind: response.data,
      });
    });
  }

  render() {
    const {dataRain, dataWind, dataTemp} = this.state;
    const {indexDevice, parameterProp} = this.props;
    let indexDeviceTab = 0;
    if (dataTemp.length !== 0 && dataWind.length !== 0 && dataRain.length !== 0 && parameterProp) {
      if (indexDevice === null){indexDeviceTab = 0}
      else {indexDeviceTab = indexDevice}
      return (
        <div className='col-lg-12'>
          <table className="table" style={{display:'block',overflow: 'auto'}}>
            <thead style={{ background: '#4FC3E9' }}>
              <tr>
                <th scope="col-lg position-sticky bg-white" style={{left:'0', position:'sticky', background: '#4FC3E9'}}></th>
                  {
                    dataTemp[0].data.map((item, i)=>(
                      <th scope="col-lg" className="text-white">
                        {' '}
                        {(`0${new Date(item.time).getHours()}`).slice(-2)}
                          :
                        {(`0${new Date(item.time).getMinutes()}`).slice(-2)}
                      </th>
                    ))
                  }
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="mt-3 mb-2 text-center" style={{left:'0', position:'sticky', background:'white'}}>
                  <img height="50" alt="Icon Cuaca" src={ICON_PARAM('temp')} />
                  <div className="text-darkGray mt-2"><b>Suhu</b></div>
                </td>
                  {
                    dataTemp[indexDeviceTab].data.map((item, i)=>(
                      <td  style={{verticalAlign: 'middle'}}>
                        <div className="text-darkGray"><b>{item.val} {parameterProp.temp.unit}</b></div>
                        <div className="text-muted text-capitalize">{item.status}</div>
                      </td>
                    ))
                  }
              </tr>
              <tr>
                <td className="mt-3 mb-2 text-center" style={{left:'0', position:'sticky', background:'white'}}>
                  <img height="50" alt="Icon Cuaca" src={ICON_PARAM('rain')} />
                  <div className="text-darkGray mt-2"><b>Curah Hujan</b></div>
                </td>
                  {
                    dataRain[indexDeviceTab].data.map((item, i)=>(
                      <td  style={{verticalAlign: 'middle'}}>
                        <div className="text-darkGray"><b>{item.val} {parameterProp.rain.unit}</b></div>
                        <div className="text-muted text-capitalize">{item.status}</div>
                      </td>
                    ))
                  }
              </tr>
              <tr>
                <td className="mt-3 mb-2 text-center" style={{left:'0', position:'sticky', background:'white'}}>
                  <img height="50" alt="Icon Cuaca" src={ICON_PARAM('wind')} />
                  <div className="text-darkGray mt-2"><b>Kecepatan Angin</b></div>
                </td>
                  {
                    dataWind[indexDeviceTab].data.map((item, i)=>(
                      <td style={{verticalAlign: 'middle'}}>
                        <div className="text-darkGray"><b>{item.val} {parameterProp.winds.unit}</b></div>
                        <div className="text-muted text-capitalize">{item.status}</div>
                      </td>
                    ))
                  }
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
    return (<h4 className="text-center text-lowBlue" style={{ height: '300px', lineHeight: '300px' }}> Prediksi Belum Tersedia </h4>);
  }
}

const mapStateToProps = (state) => ({
  nowTime:state.nowTime,
  dataDevice: state.dataDevice,
  indexDevice: state.indexDevice,
  parameterProp: state.parameterProp,
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(TableUser);
