import React, { Component } from 'react';
import { connect } from 'react-redux';
import Maps from './component/Maps';
import Table from './component/Table';
import Header from './component/Header';
import { GetDevices, PostLogin } from './api';
import {
  addParameterProp, addDeviceData, setIndexDevice, updateTime,
} from './redux/actions/actions';

//parameter varaible
let parameterProp = {};
let devices = {};

//cycle time for updating data every 10 minutes
const CYCLE_DATA = 600;

//mapping icon
const ICON_MAP = (icon) => require(`./assets/picture/icon/map-${icon}.svg`);
const LOGO_MARS = require('./assets/picture/logo-mars.svg');

// index each parameter
const indexTemp = 0;
const indexHum = 1;
const indexPress = 2;
const indexWindd = 3;
const indexWinds = 4;
const indexSolar = 5;
const indexRain = 6;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  // memberi nama di tab browser
  componentDidMount() {
    document.title = 'Overview';

    //get token from login
    PostLogin({
      email: 'paksi@marsdigital.id',
      password:'paksi123',
      strategy: 'local',
    }).then((response) => {
      // menyimpan token dalam localStorage
      localStorage.setItem('accessToken', response.data.accessToken);
    });

    // update timestamp every 1 seconds
    setInterval(this.updateTime, 1000);

    // update device data
    setInterval(
      this.updateDevice, CYCLE_DATA,
    );
  }


  updateTime=()=>{
    this.props.updateTime(new Date().getTime())
  }

  updateDevice=()=>{
    // REST GET Devices
    GetDevices(((response) => {

      devices = response.data;
      parameterProp = {
        temp: devices[0].parameterProp[indexTemp],
        hum: devices[0].parameterProp[indexHum],
        press: devices[0].parameterProp[indexPress],
        rain: devices[0].parameterProp[indexRain],
        winds: devices[0].parameterProp[indexWinds],
        windd: devices[0].parameterProp[indexWindd],
        solar: devices[0].parameterProp[indexSolar],
      };

      // store parameter properties in redux
      this.props.addParameterProp(parameterProp);

      // store device data in redux
      this.props.addDeviceData(devices);

    }
    ));
  }

  render() {
    const {dataDevice, indexDevice} = this.props;

    //store index when click tab
    let indexDeviceTab = 0;

    if (devices) {
      //when none device's clicked in map, the predicition show device 0
      if (indexDevice === null){indexDeviceTab = 0}
      else {indexDeviceTab = indexDevice}
      return (
        <div style={{overflow:'hidden'}}>
          <Header/>
          <div className="row">
            <div className="col-lg-3">
              <Maps />
            </div>
            <div className="col-lg-9">
              <h5 className="text-muted mt-4 mb-4 mr-2 ml-2">Prakiraan Cuaca <b>Besok</b></h5>
              <div className="row justify-content-around mb-4 mr-2 ml-2">
              {
                dataDevice.map((item,i)=>(
                  <div className="text-center mr-2 ml-2 mt-2 mb-3">
                    <h6 className="text-muted mb-3"><b>{item.name}</b></h6>
                    <img height="50" alt="Icon Cuaca" src={ICON_MAP('normal')} />
                    <h6 className="text-muted mt-3">Tidak Hujan</h6>
                  </div>
                ))
              }
              </div>
              <div className="ml-0 mr-0" style={{ borderBottom: 'solid 1px lightGray' }} />
              <h5 className="text-muted mb-3 mt-4 mb-4 mr-2 ml-2">Prakiraan Cuaca <b>12 Jam Mendatang</b></h5>
              <div className="m-0 mb-4 btn-group w-100" >
              {
                dataDevice.map((item,i)=>
                {
                  if (i === indexDeviceTab){
                    return (
                      <button type="button" className="btn p-2 rounded-0 m-0 btn-white col-lg" style={{borderTopColor:'#4FC3E9', borderTopWidth: '4px'}}>
                        {item.name}
                      </button>
                    )
                  }
                  return (
                    <button type="button" className="btn border p-2 rounded-0 m-0 btn-light col-lg" onClick={()=>this.props.setIndexDevice(i)}>
                      {item.name}
                    </button>
                  )
                }
              )
              }
              </div>
              <Table />
              <h6 className='text-center mb-4'><b>Powered by <a href='http://www.marsdigital.id/'><img height="40px" alt="Logo Mars" src={LOGO_MARS} /></a></b></h6>
            </div>
          </div>
        </div>
      );
    }
    return (<div className="loader" />);
  }
}

const mapDispatchToProps = {
  addParameterProp,
  addDeviceData,
  setIndexDevice,
  updateTime,
};

const mapStateToProps = (state) => ({
  parameterProp: state.parameterProp,
  nowTime: state.nowTime,
  dataDevice:state.dataDevice,
  indexDevice:state.indexDevice
});


export default connect(
  mapStateToProps, mapDispatchToProps,
)(App);
