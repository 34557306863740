import feathers from '@feathersjs/feathers';
import io from 'socket.io-client';
import socketio from '@feathersjs/socketio-client';
import authentication from '@feathersjs/authentication-client';
import axios from 'axios';

const LINK_API = 'https://api-aws.marsdigital.id';
//const LINK_API = 'http://34.101.78.199:3030';
// websocket config
const app = feathers();
const socket = io(LINK_API);

// authen config
app.configure(socketio(socket));
app.configure(authentication({
  storage: localStorage,
  storageKey: 'accessToken',
}));


const PostLogin = (props) => axios.post(`${LINK_API}/authentication`, props, {
  headers: {
    Authorization: `bearer ${localStorage.accessToken}`,
  },
})
  .then(props);

const GetListUser = (response) => {
  axios.get(`${LINK_API}/users`, {
    headers: {
      Authorization: `bearer ${localStorage.accessToken}`,
    },
  })
    .then(response);
};

const GetDevices = (response) => axios.get(`${LINK_API}/api/devices`, {
  headers: {
    Authorization: `bearer ${localStorage.accessToken}`,
  },
})
  .then(response);

const SocketDevices = (newData) => {
  app.authenticate();
  return app.service('api/devices').on('patched', newData);
};

const GetChartData = (props) => axios.get(`${LINK_API}/api/sensors-trend`, {
  params: props,
  headers: {
    Authorization: `bearer ${localStorage.accessToken}`,
  },
})
  .then(props);

const GetSnapshot = (props) => axios.get(`${LINK_API}/api/snapshot`, {
  params: props,
  headers: {
    Authorization: `bearer ${localStorage.accessToken}`,
  },
})
  .then(props);

const GetPrediction = (props) => axios.get(`${LINK_API}/api/sensors-predictions`, {
  params: props,
  headers: {
    Authorization: `bearer ${localStorage.accessToken}`,
  },
})
  .then(props);


const PostAddUser = (props) => axios.post(`${LINK_API}/users`, props, {
  headers: {
    Authorization: `bearer ${localStorage.accessToken}`,
  },
})
  .then(props);

const PatchEditUser = (props, id) => axios.patch(`${LINK_API}/users/${id}`, props, {
  headers: {
    Authorization: `bearer ${localStorage.accessToken}`,
  },
})
  .then(props);

const DeleteUser = (id) => axios.delete(`${LINK_API}/users/${id}`, {
  headers: {
    Authorization: `bearer ${localStorage.accessToken}`,
  },
})
  .then(id);

const GetNotif = (response) => {
  axios.get(`${LINK_API}/api/notifications`, {
    params: {
      'createdAt[$gte]': 1575897363633,
    },
    headers: {
      Authorization: `bearer ${localStorage.accessToken}`,
    },
  })
    .then(response);
};

const SocketNotif = (newData) => {
  app.authenticate();
  return app.service('api/notifications').on('created', newData);
};


export {
  PostLogin, GetListUser, GetDevices, GetPrediction, GetChartData,
  GetSnapshot, PostAddUser, PatchEditUser, DeleteUser, GetNotif, SocketNotif,
  SocketDevices,
};
